import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import TempHBGImage from "../components/logicom-images/cardbg/temph_bg.js"
import GreenENVBGImage from "../components/logicom-images/cardbg/green_env_bg.js"
import AgreeBGImage from "../components/logicom-images/cardbg/agree_bg.js"
import VibBGImage from "../components/logicom-images/cardbg/vibration_bg.js"
import DryBGImage from "../components/logicom-images/cardbg/dry_bg.js"
import ProfBGImage from "../components/logicom-images/cardbg/prof_bg.js"



const CardzText = (props) => (
  <Card.Text>
    {props.desc}<br/>
    <Link to={props.page}>more</Link>
  </Card.Text>
)

const Cardz = (props) => (
  <>
    <Col className="col-sm-6 mb-4">
      {/* style={{'min-width': '300px','max-width': '300px'}} */}
      <Card className="h-100">
          {/* <Card.Img variant="top" data-src="holder.js/300x160?random=yes&auto=yes"/> */}
          {props.children}
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <CardzText desc={props.desc} page={props.page}></CardzText>
        </Card.Body>
      </Card>
    </Col>
  </>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <Row>
        <Col>
          <div className="jumbotron">
            <h2>Our Business</h2>
            <p className="lead">We specialize in high quality test solutions that can simulate and record your environment. </p>
            <hr className="my-4"></hr>
            Our highly customizable test equipment is capable of accelerated stress testing systems for HALT/HASS applications, 
              temperature and humidity cycling, thermal shock, product stress screening, combined environmental testing, controlled humidity exposure, vibration testing, and functional and measurement testing.
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Our full range of products & services</h2>
            <div className="row row-cols-1 row-cols-md-3">
              <Cardz title="Professional Services" 
                desc="Meeting your organisation's needs with more than 20 years of deep expertise."
                page="/product-services/professional-svcs">
                  <ProfBGImage className={"card-img-top"}/>
              </Cardz>
              <Cardz title="Temperature and Humidity Solutions" 
                desc="Cost effective environmental test chambers of varying sizes 
                and configurations for any industry."
                page="/product-services/temperature-humidity">
                  <TempHBGImage className={"card-img-top"}/>
              </Cardz>
              <Cardz title="HALT and HASS - Accelerated Stress Test Solutions" 
                desc="Use temperature and vibration to eliminate design problems, 
                develop a better product, and screen out early product failures."
                page="/product-services/halt-hass">
                  <GreenENVBGImage className={"card-img-top"}/>
              </Cardz>
              <Cardz title="Thermal Shock Solutions" 
                desc="Seeking failure occurrences caused by different 
                materials and thermal expansion with alternating 
                extremes of high and low temperatures."
                page="/product-services/thermal-shock">
                  <DryBGImage className={"card-img-top"}/>
              </Cardz>
              <Cardz title="Vibration Test Solutions" 
                desc="Vibration Testing can determine if a product can 
                withstand the rigors of its intended use. Find out how with Thermotron's Electrodynamic Shaker
                and Repetitive Vibration Shock Table."
                page="/product-services/vibration">
                  <VibBGImage className={"card-img-top"}/>
              </Cardz>
              <Cardz title="AGREE Test Solutions" 
                desc="A Thermotron AGREE Chamber provides flexibility in your test 
                lab either as a standalone temperature chamber or vibration chamber when interfaced with
                Thermotron's Vibration test solutions."
                page="/product-services/agree">
                  <AgreeBGImage className={"card-img-top"}/>
              </Cardz>
            </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
