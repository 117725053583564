import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TempHBGImage = ({props}) => {
    const data = useStaticQuery(graphql`
    query {
        placeholderImage: file(relativePath: { eq: "temp-humid-bg.jpg" }) {
            childImageSharp {
                fluid(maxHeight: 160) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`)
  return <Img fluid={data.placeholderImage.childImageSharp.fluid} {...props}/>
}

export default TempHBGImage